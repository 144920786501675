import * as React from 'react';

export type AppContextData = {
  currentUser: {
    email: string;
    firstName: string;
    lastName: string;
  };

  sourceApp: {
    name: string;
    returnUrl: string;
    syncUrl: string;
  };
};

export const AppContext = React.createContext<AppContextData | undefined>(
  undefined,
);

export function useAppContext() {
  const context = React.useContext(AppContext);

  if (typeof context === 'undefined') {
    throw new Error('useAppContext must be used within a AppContext provider');
  }

  return context;
}
