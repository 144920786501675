import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import * as React from 'react';
import { LearnerSyncJob } from '@/__generated__/types';

export const defaultClient = createClient();

export const ApiProvider: React.FC<{ client?: ApolloClient<any> }> = ({
  children,
  client = defaultClient,
}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export function createClient(uri: string = '/api/graphql') {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache({
      typePolicies: {
        LearnerSyncConfig: {
          fields: {
            jobs: {
              merge(
                existing: LearnerSyncJob[],
                incoming: LearnerSyncJob[],
                { readField },
              ) {
                const existingMap = new Map<string, LearnerSyncJob>();
                const inserts: LearnerSyncJob[] = [];
                if (existing) {
                  existing.forEach((job) =>
                    existingMap.set(readField<string>('id', job)!, job),
                  );
                }
                incoming.forEach((job) => {
                  const id = readField<string>('id', job)!;
                  if (existingMap.has(id)) {
                    existingMap.set(id, job);
                  } else {
                    inserts.push(job);
                  }
                });

                return inserts.concat([...existingMap.values()]);
              },
            },
          },
        },
      },
    }),
  });
}
