export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AppUser = {
  __typename?: 'AppUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sourceAppSettings: SourceAppSettings;
};

export type AzureAdConfig = {
  __typename?: 'AzureAdConfig';
  connectedAt?: Maybe<Scalars['DateTime']>;
  connectedBy?: Maybe<Scalars['String']>;
  excludeUsers?: Maybe<Array<AzureAdUser>>;
  includeGroups?: Maybe<Array<AzureAdGroup>>;
  tenantEnvironment?: Maybe<Scalars['String']>;
  tenantId: Scalars['String'];
};

export type AzureAdConfigInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  excludeUsers?: InputMaybe<Array<AzureAdUserInput>>;
  includeGroups?: InputMaybe<Array<AzureAdGroupInput>>;
  tenantEnvironment?: InputMaybe<Scalars['String']>;
};

export type AzureAdGroup = {
  __typename?: 'AzureAdGroup';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AzureAdGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AzureAdSearchInput = {
  configId: Scalars['String'];
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type AzureAdSearchResult = {
  __typename?: 'AzureAdSearchResult';
  groups?: Maybe<Array<AzureAdGroup>>;
  users?: Maybe<Array<AzureAdUser>>;
};

export type AzureAdSettings = {
  __typename?: 'AzureAdSettings';
  clientId: Scalars['String'];
};

export type AzureAdTestInput = {
  accessToken: Scalars['String'];
  tenantEnvironment?: InputMaybe<Scalars['String']>;
};

export type AzureAdTestResult = {
  __typename?: 'AzureAdTestResult';
  adminConsentRequired: Scalars['Boolean'];
  adminConsentUrl?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  groupCount: Scalars['Int'];
  success: Scalars['Boolean'];
  userCount: Scalars['Int'];
};

export type AzureAdUser = {
  __typename?: 'AzureAdUser';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AzureAdUserInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type CsvUrlConfig = {
  __typename?: 'CsvUrlConfig';
  csvUrl: Scalars['String'];
};

export type CsvUrlConfigInput = {
  csvUrl: Scalars['String'];
};

export type IqServerConfig = {
  __typename?: 'IqServerConfig';
  syncGroups?: Maybe<Scalars['Boolean']>;
  syncUploadUrl?: Maybe<Scalars['String']>;
};

export type IqServerConfigInput = {
  syncGroups?: InputMaybe<Scalars['Boolean']>;
  syncUploadUrl?: InputMaybe<Scalars['String']>;
};

export type LearnerSyncConfig = Node & {
  __typename?: 'LearnerSyncConfig';
  customFields?: Maybe<Array<UserFieldMap>>;
  customerName?: Maybe<Scalars['String']>;
  destinationConfig?: Maybe<IqServerConfig>;
  /** The object's unique id */
  id: Scalars['ID'];
  job: LearnerSyncJob;
  jobs: Array<LearnerSyncJob>;
  lastSyncStarted?: Maybe<Scalars['DateTime']>;
  lastSyncStatus?: Maybe<LearnerSyncJobStatus>;
  notificationEmailAddress?: Maybe<Scalars['String']>;
  schedule?: Maybe<SyncConfigSchedule>;
  sourceConfig?: Maybe<SyncSourceConfig>;
  type: LearnerSyncConfigType;
};


export type LearnerSyncConfigJobArgs = {
  id: Scalars['String'];
};

export type LearnerSyncConfigCreateInput = {
  azureConfig?: InputMaybe<AzureAdConfigInput>;
  csvUrlConfig?: InputMaybe<CsvUrlConfigInput>;
  customFields?: InputMaybe<Array<UserFieldMapInput>>;
  customerName?: InputMaybe<Scalars['String']>;
  iqServerConfig?: InputMaybe<IqServerConfigInput>;
  notificationEmailAddress?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<SyncConfigScheduleInput>;
  type: LearnerSyncConfigType;
};

export type LearnerSyncConfigDeleteInput = {
  id: Scalars['ID'];
};

export type LearnerSyncConfigTestInput = {
  id: Scalars['ID'];
};

export type LearnerSyncConfigTestResult = {
  __typename?: 'LearnerSyncConfigTestResult';
  success: Scalars['Boolean'];
};

export enum LearnerSyncConfigType {
  AzureAd = 'AZURE_AD',
  CsvUpload = 'CSV_UPLOAD',
  CsvUrl = 'CSV_URL'
}

export type LearnerSyncConfigUpdateInput = {
  azureConfig?: InputMaybe<AzureAdConfigInput>;
  csvUrlConfig?: InputMaybe<CsvUrlConfigInput>;
  customFields?: InputMaybe<Array<UserFieldMapInput>>;
  customerName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  iqServerConfig?: InputMaybe<IqServerConfigInput>;
  notificationEmailAddress?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<SyncConfigScheduleInput>;
};

export type LearnerSyncJob = Node & {
  __typename?: 'LearnerSyncJob';
  finishedAt?: Maybe<Scalars['DateTime']>;
  /** The object's unique id */
  id: Scalars['ID'];
  logEntries: Array<SyncJobLogEntry>;
  startType: SyncJobStartType;
  startedAt?: Maybe<Scalars['DateTime']>;
  startedBy?: Maybe<Scalars['String']>;
  status: LearnerSyncJobStatus;
};

export enum LearnerSyncJobStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Queued = 'QUEUED',
  Running = 'RUNNING'
}

export enum LogEntryLevel {
  Alert = 'ALERT',
  Crit = 'CRIT',
  Debug = 'DEBUG',
  Emerg = 'EMERG',
  Err = 'ERR',
  Info = 'INFO',
  Notice = 'NOTICE',
  Warning = 'WARNING'
}

export type Mutation = {
  __typename?: 'Mutation';
  createConfig: LearnerSyncConfig;
  deleteConfig: StatusMessage;
  deleteCustomer: StatusMessage;
  runSyncJob: LearnerSyncJob;
  updateConfig: LearnerSyncConfig;
};


export type MutationCreateConfigArgs = {
  input: LearnerSyncConfigCreateInput;
};


export type MutationDeleteConfigArgs = {
  input: LearnerSyncConfigDeleteInput;
};


export type MutationRunSyncJobArgs = {
  input: RunSyncJobInput;
};


export type MutationUpdateConfigArgs = {
  input: LearnerSyncConfigUpdateInput;
};

export type Node = {
  /** The object's unique id */
  id: Scalars['ID'];
};

export enum OrderByArg {
  Asc = 'asc',
  Desc = 'desc'
}

export type Query = {
  __typename?: 'Query';
  azureAdGroupSearch: AzureAdSearchResult;
  azureAdSettings: AzureAdSettings;
  azureAdTest: AzureAdTestResult;
  azureAdUserSearch: AzureAdSearchResult;
  config: LearnerSyncConfig;
  configs: Array<LearnerSyncConfig>;
  me: AppUser;
  syncJob: LearnerSyncJob;
  testConfig: LearnerSyncConfigTestResult;
  version: ServerVersion;
};


export type QueryAzureAdGroupSearchArgs = {
  input: AzureAdSearchInput;
};


export type QueryAzureAdTestArgs = {
  input: AzureAdTestInput;
};


export type QueryAzureAdUserSearchArgs = {
  input: AzureAdSearchInput;
};


export type QueryConfigArgs = {
  id: Scalars['String'];
};


export type QuerySyncJobArgs = {
  id: Scalars['String'];
};


export type QueryTestConfigArgs = {
  input: LearnerSyncConfigTestInput;
};

export type RunSyncJobInput = {
  configId: Scalars['String'];
};

export type ServerVersion = {
  __typename?: 'ServerVersion';
  branch: Scalars['String'];
  commit: Scalars['String'];
  date: Scalars['String'];
};

export type SourceAppSettings = {
  __typename?: 'SourceAppSettings';
  name: Scalars['String'];
  returnUrl: Scalars['String'];
  syncUrl: Scalars['String'];
};

export type StatusMessage = {
  __typename?: 'StatusMessage';
  message: Scalars['String'];
};

export type SyncConfigSchedule = {
  __typename?: 'SyncConfigSchedule';
  enabled: Scalars['Boolean'];
  startHour?: Maybe<Scalars['Int']>;
};

export type SyncConfigScheduleInput = {
  enabled: Scalars['Boolean'];
  startHour?: InputMaybe<Scalars['Int']>;
};

export type SyncJobLogEntry = {
  __typename?: 'SyncJobLogEntry';
  level: LogEntryLevel;
  message: Scalars['String'];
  timeStamp: Scalars['DateTime'];
};

export enum SyncJobStartType {
  Manual = 'MANUAL',
  Scheduled = 'SCHEDULED'
}

export type SyncSourceConfig = AzureAdConfig | CsvUrlConfig;

export type UserFieldMap = {
  __typename?: 'UserFieldMap';
  destination: Scalars['String'];
  source: Scalars['String'];
};

export type UserFieldMapInput = {
  destination: Scalars['String'];
  source: Scalars['String'];
};
