import {
  AzureAdConfig,
  LearnerSyncConfig,
  LearnerSyncConfigType,
  LearnerSyncJob,
} from './__generated__/types';

export type SyncConfig = Omit<LearnerSyncConfig, 'job' | 'jobs'>;

export type AzureActiveDirectorySyncConfig = SyncConfig & {
  sourceConfig: AzureAdConfig;
  type: LearnerSyncConfigType.AzureAd;
};

export type CsvUploadSyncConfig = SyncConfig & {
  type: LearnerSyncConfigType.CsvUpload;
};

export const isAzureActiveDirectorySyncConfig = (
  config: SyncConfig,
): config is AzureActiveDirectorySyncConfig => {
  const adConfig = config as AzureActiveDirectorySyncConfig;

  return (
    adConfig.type === LearnerSyncConfigType.AzureAd &&
    !!adConfig.sourceConfig.tenantId
  );
};

export type AzureActiveDirectoryJobSummary = Omit<LearnerSyncJob, 'logEntries'>;
