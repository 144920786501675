import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SourceAppSettings } from './__generated__/types';
import { Button } from './shared/components/button';
import { Container } from './shared/components/container';
import { Logo } from './shared/components/logo';
import { Text } from './shared/components/text';

type Props = {
  sourceApp?: SourceAppSettings;
};

export function Header({ sourceApp }: Props) {
  return (
    <Root>
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <Logo />{' '}
            <Text color="#888" weight="medium">
              Learner Sync
            </Text>
          </Link>
        </HeaderLogo>
        <HeaderContent>
          {!!sourceApp?.returnUrl && (
            <Button
              onClick={() => {
                window.location.assign(sourceApp.returnUrl);
              }}
            >
              Return to IQ
            </Button>
          )}
        </HeaderContent>
      </HeaderContainer>
    </Root>
  );
}

// STYLES

const Root = styled('header')`
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`;

const HeaderContainer = styled(Container)`
  align-items: center;
  display: flex;
  height: 55px;
  margin: auto;
`;

const HeaderLogo = styled('div')`
  flex: 0 0 350px;
  width: 350px;

  svg {
    flex: 0 0 120px;
    margin-right: 16px;
    width: 120px;
  }

  a:link,
  a:visited {
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration: none;
  }
`;

const HeaderContent = styled('div')`
  margin-left: auto;

  button {
    width: 180px;
  }
`;
