import styled, { css } from 'styled-components';

type TextWeight = 'normal' | 'medium' | 'bold';

type Props = {
  color?: string;
  size?: number;
  truncateOverflow?: boolean;
  weight?: TextWeight;
  wordBreak?: 'normal' | 'break-all' | 'break-word';
};

export const Text = styled('span')<Props>`
  color: ${(p) => p.color ?? '#3c4257'};
  font-weight: ${(p) => WeightMap[p.weight ?? 'normal']};
  font-size: ${(p) => (p.size ? `${p.size}px` : 'inherit')};
  word-break: ${(p) => p.wordBreak ?? 'normal'};

  ${(p) =>
    p.truncateOverflow &&
    css`
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;

const WeightMap: Record<TextWeight, number> = {
  bold: 700,
  medium: 500,
  normal: 400,
};
