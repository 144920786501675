import styled, { css } from 'styled-components';

type ButtonAppearance = 'primary' | 'secondary' | 'subtle';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  appearance?: ButtonAppearance;
};

export const Button = styled('button')<Props>`
  border: 2px solid;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-height: 42px;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out, transform 0.1s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }

  ${(p) =>
    (!p.appearance || p.appearance === 'primary') &&
    css`
      background-color: #009ace;
      border-color: #009ace;
      color: #fff;

      &:hover {
        background-color: #007ba5;
        border-color: #007ba5;
      }
    `};

  ${(p) =>
    p.appearance === 'secondary' &&
    css`
      background-color: transparent;
      border-color: #009ace;
      color: #009ace;

      &:hover {
        border-color: #007ba5;
        color: #007ba5;
      }
    `};

  ${(p) =>
    p.appearance === 'subtle' &&
    css`
      background-color: transparent;
      border-color: #838383;
      color: #696969;

      &:hover {
        border-color: #5c5c5c;
        color: #434343;
      }
    `};
`;
