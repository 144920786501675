import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export function Footer() {
  return (
    <Root>
      <div className="inner">
        <a href="https://www.cengagegroup.com/privacy/" target="blank">
          Privacy
        </a>
      </div>
    </Root>
  );
}

const Root = styled('div')`
  display: flex;
  padding: 10px 2px;
  position: relative;
  max-width: 1000px;
  margin: auto;

  div.inner {
    margin-left: auto;

    a:link {
      color: #005a7c;
      text-decoration: none;
    }
  }
`;
