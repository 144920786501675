import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { lazy } from '@/shared/utils/lazy';
import { useAppDataQuery } from './__generated__/app-query';
import { AppContext } from './app-context';
import { Header } from './header';
import { Configs } from './modules/configs';
import { Container } from './shared/components/container';
import { Footer } from './shared/components/footer';
import { ApiProvider } from './shared/services/api-provider';
import { unit } from './shared/styles';
import { SyncConfig } from './types';

export function App() {
  return (
    <ApiProvider>
      <BrowserRouter>
        <AppRoot />
      </BrowserRouter>
    </ApiProvider>
  );
}

const Dashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ './modules/dashboard'),
  'Dashboard',
);

const Setup = lazy(
  () => import(/* webpackChunkName: "setup" */ './modules/setup'),
  'Setup',
);

function AppRoot() {
  const { data, error, loading } = useAppDataQuery();

  const contextValue = React.useMemo(() => {
    if (!data?.me) return;

    return {
      currentUser: {
        email: data.me.email,
        firstName: data.me.firstName,
        lastName: data.me.lastName,
      },

      sourceApp: data.me.sourceAppSettings,
    };
  }, [data?.me]);

  if (loading) return null;

  let showSetup = false;
  let setupConfig: SyncConfig | undefined;

  if (data && data.configs.length === 0) {
    showSetup = true;
  }

  return (
    <>
      <GlobalStyles />
      <AppContext.Provider value={contextValue}>
        <Header sourceApp={contextValue?.sourceApp} />
        <div>
          {error || !data ? (
            <ErrorMessage>{error?.message ?? 'ERROR!'}</ErrorMessage>
          ) : (
            <React.Suspense fallback={null}>
              <Container>
                <Switch>
                  <Route path="/setup">
                    <Setup config={setupConfig} />
                  </Route>
                  <Route path="/configs/:id">
                    <Configs />
                  </Route>
                  <Route path="/" exact={true}>
                    {showSetup ? (
                      <Redirect to="/setup" />
                    ) : (
                      <Dashboard configs={data.configs} />
                    )}
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </Container>
            </React.Suspense>
          )}
        </div>
        <Footer />
      </AppContext.Provider>
    </>
  );
}

//

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: #e4e6e6;
    box-sizing: border-box;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  input, button {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  }
`;

const ErrorMessage = styled(Container)`
  align-items: center;
  border: 2px solid #d8dadb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: ${unit(3)}px auto;
  min-height: ${unit(20)}px;
`;
